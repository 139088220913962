import {combineReducers} from 'redux';
import * as types from './types';

export const isLoggingIn = (state = false, {type}) => {
  switch(type){
    case types.USER_SIGN_IN_REQUEST:{
      return true;
    }
    case types.USER_SIGN_IN_SUCCESS: {
      return false;
    }
    case types.USER_SIGN_IN_FAILURE: {
      return false;
    }
    default:
      return state;
  }
};

export const isLoggedIn = (state = false, {type}) => {
  switch(type){
    case types.USER_SIGN_IN_SUCCESS:{
      return true;
    }
    case types.USER_SIGN_OUT:{
      return false;
    }
    default:
      return state;
  }
};

export const isError = (state = false, {type}) => {
  switch(type){
    case types.USER_SIGN_IN_FAILURE: {
      return true;
    }
    case types.USER_SIGN_IN_RESET:
    case types.USER_SIGN_IN_SUCCESS: {
      return false;
    }
    default:
      return state;
  }
}

export const userInfo = (state = null, {type,payload}) => {
  switch(type){
    case types.USER_SIGN_IN_SUCCESS:{
      return payload.user;
    }
    case types.USER_SIGN_OUT:{
      return null;
    }
    default:
      return state;
  }
};

export const accessToken = (state = null, {type, payload}) => {

  switch(type){
    case types.USER_SIGN_IN_SUCCESS:{
      return payload.accessToken;
    }
    case types.USER_SIGN_OUT:{
      return null;
    }
    default:
      return state;
  }
};

export const isValidatingToken = (state = false, {type}) => {
  switch(type){
    case types.USER_VALIDATE_TOKEN_REQUEST:{
      return true;
    }
    case types.USER_VALIDATE_TOKEN_FAILURE: {
      return false;
    }
    case types.USER_VALIDATE_TOKEN_SUCCESS: {
      return false;
    }
    default:
      return state;
  }
};

export default combineReducers({isLoggingIn, isLoggedIn, isError, accessToken, userInfo, isValidatingToken});