import * as types from './types';

export const addItemToBasket = item => ({type: types.ADD_ITEM, payload: item });
export const minusItemCount = item => ({type: types.MINUS_ITEM_COUNT, payload: item });
export const plusItemCount = item => ({type: types.PLUS_ITEM_COUNT, payload: item });
export const setAddedItemCount = (itemId, addedItemCount) => ({type: types.SET_ADDED_ITEM_COUNT, payload: {itemId, addedItemCount} });
export const setItemCount = (itemId, count) => ({type: types.SET_ITEM_COUNT, payload: {itemId, count}})
export const resetBasket = () => ({type: types.RESET_BASKET, payload: {}})
export const showBasket = () => ({type: types.SHOW_BASKET});
export const hideBasket = () => ({type: types.HIDE_BASKET});
export const showMiniBasket = () => ({type: types.SHOW_MINI_BASKET});
export const hideMiniBasket = () => ({type: types.HIDE_MINI_BASKET});
export const showAddedToBasketModal = () => ({type: types.SHOW_ADDED_TO_BASKET_MODAL});
export const hideAddedToBasketModal = () => ({type: types.HIDE_ADDED_TO_BASKET_MODAL});
export const showKlarnaModal = () => ({type: types.SHOW_KLARNA_MODAL});
export const hideKlarnaModal = () => ({type: types.HIDE_KLARNA_MODAL});
export const showBasketError = () => ({type: types.SHOW_BASKET_ERROR})
export const hideBasketError = () => ({type: types.HIDE_BASKET_ERROR})
export const saveFormDetails = formDetails => ({ type: types.SAVE_FORM_DETAILS, payload: formDetails })

export const addAdditionalServices = additionalServices => ({ type: types.ADD_ADDITIONAL_SERVICES, payload: additionalServices })
export const removeAdditionalServices = additionalServices => ({ type: types.REMOVE_ADDITIONAL_SERVICES, payload: additionalServices })

export const showAdditionalServices = () => ({ type: types.SHOW_ADDITIONAL_SERVICES })
export const hideAdditionalServices = () => ({ type: types.HIDE__ADDITIONAL_SERVICES })
export const removeCouponCode = () => ({ type: types.REMOVE_COUPON_CODE })
export const addCouponCode = (payload) =>  ({ type: types.ADD_COUPON_CODE, payload })
export const submitCouponCode = () => ({ type: types.SUBMIT_COUPON_CODE })
export const invalidCouponCode = (payload) => ({ type: types.INVALID_COUPON_CODE, payload })
export const addShippingFee = () => ({type: types.ADD_SHIPPING_FEE})
export const removeShippingFee = () => ({type: types.REMOVE_SHIPPING_FEE})