/*
{
    "success": true,
    "statusCode": 200,
    "code": "jwt_auth_valid_credential",
    "message": "Credential is valid",
    "data": {
        "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvcG9pbnRzLmNvdXZlZS5jby5pZCIsImlhdCI6MTU4ODQ5OTE0OSwibmJmIjoxNTg4NDk5MTQ5LCJleHAiOjE1ODkxMDM5NDksImRhdGEiOnsidXNlciI6eyJpZCI6MX19fQ.w3pf5PslhviHohmiGF-JlPZV00XWE9c2MfvBK7Su9Fw",
        "id": 1,
        "email": "contactjavas@gmail.com",
        "nicename": "contactjavas",
        "firstName": "Bagus Javas",
        "lastName": "Heruyanto",
        "displayName": "contactjavas"
    }
}
 */

export const parseUserInfo = data => {
  const { id, email, nicename, firstName, lastName, displayName} = data;

  return {id, email, nicename, firstName, lastName, displayName};
}

export const parseUserToken = data => {
  const { token } = data;

  return token;
}