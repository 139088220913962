import "@fontsource/roboto/latin-400.css"
import "@fontsource/roboto/latin-500.css"
import "@fontsource/roboto-slab/latin-400.css"
import "@fontsource/roboto-slab/latin-600.css"
import "@fontsource/vujahday-script/latin-400.css"
require("./src/styles/index.scss");
require("./src/styles/global.css");


// without the below the position is not reset when navigating to new page
export const onRouteUpdate = () => {
    if (typeof window !== 'undefined') {
        window.scrollTo(0, 0)
    }
}

export { default as wrapRootElement } from './src/state/ReduxWrapper';
