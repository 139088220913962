import * as ordersActions from './actions';
import * as ordersOperations from './operations';
import * as ordersSelectors from './selectors';
import * as ordersTypes from './types';
import reducer from './reducers';

export {
  ordersActions,
  ordersOperations,
  ordersSelectors,
  ordersTypes,
}

export default reducer;