import { createActionType } from "../../utils/redux"

const namespace = 'auth_';

export const USER_SIGN_IN_RESET = createActionType(namespace, 'USER_SIGN_IN_RESET');

export const USER_SIGN_IN_SUCCESS = createActionType(namespace,'USER_SIGN_IN_SUCCESS');
export const USER_SIGN_IN_REQUEST = createActionType(namespace,'USER_SIGN_IN_REQUEST');
export const USER_SIGN_IN_FAILURE = createActionType(namespace,'USER_SIGN_IN_FAILURE');

export const USER_SIGN_OUT = createActionType(namespace,'USER_SIGN_OUT');

export const USER_VALIDATE_TOKEN_REQUEST = createActionType(namespace,'USER_VALIDATE_TOKEN_REQUEST');
export const USER_VALIDATE_TOKEN_SUCCESS = createActionType(namespace,'USER_VALIDATE_TOKEN_SUCCESS');
export const USER_VALIDATE_TOKEN_FAILURE = createActionType(namespace,'USER_VALIDATE_TOKEN_FAILURE');