import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension"
import rootReducer from '.';

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStoreProd = initialState => {
  return reduxCreateStore(
    persistedReducer,
    initialState,
    composeWithDevToolsDevelopmentOnly(
      applyMiddleware(
        thunk,
      )
    )
  );
}


// eslint-disable-next-line react/display-name,react/prop-types,import/no-anonymous-default-export
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const createStore = () => configureStoreProd();
  const store = createStore();
  persistStore(store)

  return (
    <Provider store={store}>{element}</Provider>
  )
};