import * as types from './types';

export const signInUserReset = () => ({type: types.USER_SIGN_IN_RESET});

export const signInUserRequest = () => ({type: types.USER_SIGN_IN_REQUEST});
export const singInUserSuccess = data => ({type: types.USER_SIGN_IN_SUCCESS, payload: data});
export const signInUserFailure = error => ({type: types.USER_SIGN_IN_FAILURE, payload: error});

export const signOutUser = () => ({type: types.USER_SIGN_OUT});

export const validateTokenUserRequest = () => ({type: types.USER_VALIDATE_TOKEN_REQUEST});
export const validateTokenUserSuccess = data => ({type: types.USER_VALIDATE_TOKEN_SUCCESS, payload: data});
export const validateTokenUserFailure = error => ({type: types.USER_VALIDATE_TOKEN_FAILURE, payload: error});