const namespace = 'basket_';

export const ADD_ITEM = namespace + 'ADD_ITEM';
export const PLUS_ITEM_COUNT = namespace + 'PLUS_ITEM_COUNT';
export const MINUS_ITEM_COUNT = namespace + 'MINUS_ITEM_COUNT';
export const SET_ADDED_ITEM_COUNT = namespace + 'SET_ADDED_ITEM_COUNT';
export const SET_ITEM_COUNT = namespace + 'SET_ITEM_COUNT'
export const RESET_BASKET = namespace + 'RESET_BASKET'
export const SHOW_BASKET = namespace + 'SHOW_BASKET'
export const HIDE_BASKET = namespace + 'HIDE_BASKET'
export const SHOW_MINI_BASKET = namespace + 'SHOW_MINI_BASKET'
export const HIDE_MINI_BASKET = namespace + 'HIDE_MINI_BASKET'
export const SHOW_ADDED_TO_BASKET_MODAL = namespace + 'SHOW_ADDED_TO_BASKET_MODAL'
export const HIDE_ADDED_TO_BASKET_MODAL = namespace + 'HIDE_ADDED_TO_BASKET_MODAL'
export const SHOW_KLARNA_MODAL = namespace + 'SHOW_KLARNA_MODAL'
export const HIDE_KLARNA_MODAL = namespace + 'HIDE_KLARNA_MODAL'
export const SHOW_BASKET_ERROR = namespace + 'SHOW_BASKET_ERROR'
export const HIDE_BASKET_ERROR = namespace + 'HIDE_BASKET_ERROR'
export const SAVE_FORM_DETAILS = namespace + 'SAVE_FORM_DETAILS'
export const ADD_ADDITIONAL_SERVICES = namespace + 'ADD_ADDITIONAL_SERVICES';
export const REMOVE_ADDITIONAL_SERVICES = namespace + 'REMOVE_ADDITIONAL_SERVICES';
export const SHOW_ADDITIONAL_SERVICES = namespace + 'SHOW_ADDITIONAL_SERVICES';
export const HIDE__ADDITIONAL_SERVICES = namespace + 'HIDE__ADDITIONAL_SERVICES';
export const ADD_COUPON_CODE = namespace + 'ADD_COUPON_CODE';
export const SUBMIT_COUPON_CODE = namespace + 'SUBMIT_COUPON_CODE';
export const REMOVE_COUPON_CODE = namespace + 'REMOVE_COUPON_CODE';
export const INVALID_COUPON_CODE = namespace + 'INVALID_COUPON_CODE';
export const ADD_SHIPPING_FEE = namespace + 'ADD_SHIPPING_FEE';
export const REMOVE_SHIPPING_FEE = namespace + 'REMOVE_SHIPPING_FEE';

