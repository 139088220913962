import {createSelector} from 'reselect';

const selectAuthState = state => state.auth;

export const isLoggingIn = createSelector(
  [selectAuthState],
  (auth) => {
    return auth.isLoggingIn;
  }
);

export const isLoggedIn = createSelector(
  [selectAuthState],
  (auth) => {
    return auth.isLoggedIn;
  }
);

export const isError = createSelector(
  [selectAuthState],
  (auth) => {
    return auth.isError;
  }
)

export const getUser = createSelector(
  [selectAuthState],
  (auth) => {
    return auth.userInfo;
  }
)