import {combineReducers} from 'redux';
import * as types from './types';

export const isFetchingOrders = (state = false, {type}) => {
  switch(type){
    case types.FETCHING_ORDER_REQUEST:{
      return true;
    }
    case types.FETCHING_ORDER_FAILURE:
    case types.FETCHING_ORDER_SUCCESS: {
      return false;
    }
    default:
      return state;
  }
};

export const orders = (state = [], {type, payload}) => {
  switch(type){
    case types.FETCHING_ORDER_SUCCESS:{
      return payload;
    }
    case types.FETCHING_ORDER_FAILURE:{
      return [];
    }
    default:
      return state;
  }
};

export const isError = (state = false, {type}) => {
  switch(type){
    case types.FETCHING_ORDER_FAILURE: {
      return true;
    }
    case types.FETCHING_ORDER_SUCCESS:
    case types.FETCHING_ORDER_REQUEST: {
      return false;
    }
    default:
      return state;
  }
}


export default combineReducers({isFetchingOrders, orders, isError});