export const rawPrice = price => {

  if(price && (typeof price === 'string' || price instanceof String)) {
    return price.replace('£','')
      .replace('$','')
      .replace(',','')
      .replace(' ','')
      .replace(process.env.CURRENCY_SYMBOL,'')
      .trim();
  }

  return price;
}