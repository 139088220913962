import axios from 'axios';

const BASE_URL = process.env.STRIPE_SVC_BACKEND_BASE_URL;
//const BASE_URL = "http://localhost:8080";//process.env.STRIPE_SVC_BACKEND_BASE_URL;

export const fetchOrders = (token) => {

  const config = {
    method: 'GET',
    baseURL: BASE_URL,
    url: '/api/v1/customer/orders',
    headers: { Authorization: `Bearer ${token}` },
  };

  return axios(config);

}