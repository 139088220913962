import * as types from "./types"
import { combineReducers } from "redux"
import _omit from "lodash/omit"
import { getProductBasketProps } from "../../utils/productsHelper"

const show = (state = false, { type }) => {
  switch (type) {
    case types.SHOW_BASKET:
    case types.ADD_ITEM:
      return true
    case types.HIDE_BASKET:
    case types.RESET_BASKET:
      return false
    default:
      return state
  }
}

const items = (state = {}, { type, payload }) => {
  switch (type) {
    case types.ADD_ITEM: {
      let item
      const { product, itemsToAdd } = payload
      const productProps = getProductBasketProps(product)

      if (!productProps.price) {
        return state
      } else if (state[productProps.id] === undefined) {
        const stockUpdate = productProps.stockQuantity - itemsToAdd
        item = {
          itemId: productProps.id,
          item: product,
          count: itemsToAdd,
          updatedStock: stockUpdate,
          addedItemCount: itemsToAdd,
        }

        return { ...state, [productProps.id]: item }
      } else {
        item = state[productProps.id]
        item.count = item.count + itemsToAdd
        item.addedItemCount = itemsToAdd

        if (
          item.updatedStock === undefined &&
          productProps.stockQuantity !== undefined
        ) {
          item.updatedStock = productProps.stockQuantity - itemsToAdd
        } else if (productProps.stockQuantity !== undefined) {
          item.updatedStock = item.updatedStock - itemsToAdd
        }

        return { ...state, [productProps.id]: item }
      }
    }
    case types.SET_ITEM_COUNT: {
      const { itemId, count } = payload
      if (state[itemId] === undefined) {
        return state
      }
      const item = state[itemId]
      item.count = count
      if (count <= 0) {
        return _omit(state, [itemId])
      } else {
        return { ...state, [itemId]: item }
      }
    }
    case types.SET_ADDED_ITEM_COUNT: {
      const { itemId, addedItemCount } = payload
      if (state[itemId] === undefined) {
        return state
      }
      const item = state[itemId]
      item.addedItemCount = addedItemCount
      if (addedItemCount <= 0) {
        return _omit(state, [itemId])
      } else {
        return { ...state, [itemId]: item }
      }
    }
    case types.PLUS_ITEM_COUNT: {
      let item
      const productProps = getProductBasketProps(payload)

      if (state[productProps.id] === undefined) {
        return state
      } else {
        item = state[productProps.id]

        if (
          productProps.stockQuantity &&
          item.count + 1 > productProps.stockQuantity
        ) {
          // pass
        } else {
          item.count++
          item.updatedStock--
        }

        return { ...state, [productProps.id]: item }
      }
    }
    case types.MINUS_ITEM_COUNT: {
      let item
      const productProps = getProductBasketProps(payload)

      if (state[productProps.id] === undefined) {
        return state
      }
      item = state[productProps.id]
      item.count--

      if (
        item.updatedStock === undefined &&
        productProps.stockQuantity !== undefined
      ) {
        item.updatedStock = productProps.stockQuantity + 1
      } else if (productProps.stockQuantity !== undefined) {
        item.updatedStock++
      }

      if (item.count <= 0) {
        return _omit(state, [productProps.id])
      } else {
        return { ...state, [productProps.id]: item }
      }
    }
    case types.RESET_BASKET: {
      return {}
    }
    default: {
      return state
    }
  }
}

const toggleMiniBasket = (state = false, { type }) => {
  switch (type) {
    case types.SHOW_MINI_BASKET:
      return true
    case types.HIDE_MINI_BASKET:
      return false
    default:
      return state
  }
}

const isShippingSelectedReducer = (state=false, { type }) => {
  switch(type) {
    case types.ADD_SHIPPING_FEE:
      return true
    case types.REMOVE_SHIPPING_FEE:
      return false
    default:
      return state
  }
}

const toggleAdditionalServices = (state = false, { type }) => {
  switch (type) {
    case types.SHOW_ADDITIONAL_SERVICES:
      return true
    case types.HIDE__ADDITIONAL_SERVICES:
      return false
    default:
      return state
  }
}
const toggleAddedToBasketModal = (state = false, { type }) => {
  switch (type) {
    case types.SHOW_ADDED_TO_BASKET_MODAL:
      return true
    case types.HIDE_ADDED_TO_BASKET_MODAL:
      return false
    default:
      return state
  }
}

const toggleKlarnaModal = (state = false, { type }) => {
  switch (type) {
    case types.SHOW_KLARNA_MODAL:
      return true
    case types.HIDE_KLARNA_MODAL:
      return false
    default:
      return state
  }
}

const setBasketError = (state = false, { type }) => {
  switch (type) {
    case types.SHOW_BASKET_ERROR:
      return true
    case types.HIDE_BASKET_ERROR:
      return false
    default:
      return state
  }
}

const saveFormDetails = (state = {}, { type, payload }) => {
  switch (type) {
    case types.SAVE_FORM_DETAILS:
      return { ...state, payload }
    default:
      return state
  }
}

const additionalServices = (state = false, { type }) => {
  switch (type) {
    case types.ADD_ADDITIONAL_SERVICES:
      return true
    case types.REMOVE_ADDITIONAL_SERVICES:
      return false
    default:
      return state
  }
}

const couponCode = (state = {}, { type, payload }) => {
  switch (type) {
    case types.ADD_COUPON_CODE:
      return payload
    case types.INVALID_COUPON_CODE:
      return payload
    case types.REMOVE_COUPON_CODE:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  items,
  show,
  toggleMiniBasket,
  toggleAddedToBasketModal,
  toggleKlarnaModal,
  setBasketError,
  saveFormDetails,
  additionalServices,
  toggleAdditionalServices,
  couponCode,
  isShippingSelectedReducer
})
