import * as actions from './actions';
import * as api from '../../api/orders';
import {getToken} from '../auth/operations'

export const fetchOrders = () => (dispatch, getState) => {
  dispatch(actions.fetchingOrdersRequest())
  getToken(dispatch, getState, token => dispatch => {
    api.fetchOrders(token).then(response => {
      const orders = response.data
      dispatch(actions.fetchingOrdersSuccess(orders))
    }).catch(error=> {
      dispatch(actions.fetchingOrdersFailure(error))
    })
  })
}