import * as actions from './actions';
import * as api from '../../api/auth';
import * as utils from '../../utils/auth';
import { navigate } from "gatsby";

export const signInUser = (username, password) => dispatch => {
  dispatch(actions.signInUserRequest());
  api.fetchToken(username,password)
    .then(response => {
      const {data} = response.data;
      const user = utils.parseUserInfo(data);
      const accessToken = utils.parseUserToken(data);
      dispatch(actions.singInUserSuccess({accessToken,  user }));
    }).catch(error => {
    dispatch(actions.signInUserFailure(error));
  })
};

export const signOutUser = () => dispatch => {
  dispatch(actions.signOutUser());
};

export const silentSignOutUser = () => dispatch => {
  dispatch(actions.signOutUser());
  navigate('/login')
}

export const signInUserReset = () => dispatch => {
  dispatch(actions.signInUserReset());
}

const buffor = [];

export const getToken = (dispatch, getState, secureCallBack) => {
  const {auth} = getState();
  const {isLoggedIn, accessToken, isValidatingToken} = auth;

  if(isValidatingToken){
    buffor.push(secureCallBack());
    return;
  }

  if(!isLoggedIn){
    dispatch(silentSignOutUser());
    return;
  }

  if(!accessToken){
    dispatch(silentSignOutUser());
    return;
  }

  dispatch(actions.validateTokenUserRequest());
  api.validateToken(accessToken)
    .then(response => {
      dispatch(actions.validateTokenUserSuccess(response.data));
      dispatch(secureCallBack(accessToken));
      try {
        while (buffor.length) {
          const cb = buffor.shift();
          dispatch(cb(accessToken));
        }
      }catch(e){
        console.error('Executing request',e);
      }
    })
    .catch( error=> {
      //Error validating token Error: Actions must be plain objects. Use custom middleware for async actions.
      console.log('Error validating token',error)
      dispatch(actions.validateTokenUserFailure(error));
      dispatch(silentSignOutUser());
      buffor.length = 0;
    });

};
