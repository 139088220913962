import { createSelector } from "reselect"
import { values as _values, mapValues as _mapValues } from "lodash/object"
import { rawPrice } from "../../utils/pricesHelper"
import {isProductAnElectricBicycle} from "../../utils/productsHelper";

export const selectBasketItemsState = state => state.basket.items
const selectBasketState = state => state.basket
const selectDeliveryOption = state => state.basket.saveFormDetails

export const getCouponCode = createSelector([selectBasketState], basket => basket.couponCode.code)
export const getCouponAmount = createSelector([selectBasketState], basket => basket.couponCode.amount)
export const getCouponType = createSelector([selectBasketState], basket => basket.couponCode.discount_type)
export const isCouponCodeValid = createSelector([selectBasketState], basket => basket.couponCode.valid)
export const isShippingSelected = createSelector([selectBasketState], basket => basket.isShippingSelectedReducer)

export const getExtraFee = createSelector([selectBasketItemsState], basket => Object.values(basket).map(product => {
return product.item.nodeType === "SimpleProduct"
  ? product.item?.beforeAddToBasketNotification?.extraFee
  : product.item?.genericProductData.beforeAddToBasketNotification?.extraFee}))


const isShippingEnabled = Boolean(Number(process.env.ENABLE_SHIPPING_FEE))

export const getItems = createSelector([selectBasketItemsState], items => {
  return _values(items)
})

export const getTotalItems = createSelector([selectBasketItemsState], items => {
  const data = _values(items)
  let total = 0

  data.forEach(element => (total += element.count))

  return total
})

export const getTotalItemsValue = createSelector(
  [selectBasketItemsState],
  items => {
    const data = _values(items)
    let total = 0

    data.forEach(element => {
      let price = Number(element.item.price)
      total += element.count * price
    })
    return total
  }
)

export const isBikeInTheBasket = createSelector(
    [selectBasketItemsState],
    items => {
        const data = _values(items)
        let bikePresent = false

        data.forEach(element => {
            bikePresent = isProductAnElectricBicycle(element.item)
        })
        return bikePresent
    }
)

export const getBasketItemsIdAndCount = createSelector(
  [selectBasketItemsState],
  items => {
    return _mapValues(items, item => item.count)
  }
)

export const getProductDataFromBasket = createSelector(
  [selectBasketItemsState],
  items => {
    const data = _values(items).map(item => {
      if (item.item.nodeType === "SimpleProduct") {
        return {
          productId: item.item.databaseId,
          variantId: null,
          count: item.count,
          associatedProductId: item.item.beforeAddToBasketNotification.extraFee?.associatedProductId,
        }
      } else {
        return {
          productId: item.item.genericProductData.databaseId,
          variantId: item.item.variantData.databaseId,
          count: item.count,
          associatedProductId: item.item.genericProductData.beforeAddToBasketNotification?.extraFee?.associatedProductId,
        }
      }
    })

    return { products: data }
  }
)

export const getDeliveryOptionDetails = createSelector(
  [selectDeliveryOption],
  ui => {
    return ui.payload
  }
)

export const getAdditionalServices = createSelector([selectBasketState], ui => {
  return ui.additionalServices
})

export const getBasketTotal = createSelector(
  [selectBasketItemsState],
  items => {

    let data = _values(items).map(({ count, item }) => {
      let price = item.price
      if (!price) {
        price = item.variantData.price
      }

      return count * rawPrice(price)
    })

    if (data.length > 0) {
      data = data.reduce((x, y) => x + y)
    } else {
      data = 0
    }

    return data
  }
)
export const calculateShippingFee = createSelector([getBasketTotal, isShippingSelected], (total, isShippingSelected) => {
   if (
     !isShippingEnabled ||
     !isShippingSelected ||
     total > process.env.FREE_SHIPPING_MINIMUM
   ) {
     return 0
   }

   return Number(process.env.SHIPPING_FEE)
})

export const getBasketTotalWithDiscount = createSelector(
  [getBasketTotal, getCouponAmount, getCouponType, calculateShippingFee ],
  (total, discountAmount, discountType, calculatedShippingFee ) => {
    switch(discountType) {
      case 'fixed_cart':
        return (Number(total) - discountAmount) + calculatedShippingFee
      case 'percent':
        return (Number(total) * (1 - (discountAmount / 100))) + calculatedShippingFee
      case 'fixed_product':
          console.error('this discount type is not supported')
        return  calculatedShippingFee + total
      default:
        return calculatedShippingFee + total
    }
  }
)

export const isVisible = createSelector([selectBasketItemsState], ui => {
  return ui.show
})
export const isAdditionalServicesVisible = createSelector(
  [selectBasketState],
  ui => {
    return ui.toggleAdditionalServices
  }
)
export const isMiniBaskteVisible = createSelector([selectBasketState], ui => {
  return ui.toggleMiniBasket
})

export const isAddedToBasketModalVisible = createSelector(
  [selectBasketState],
  ui => {
    return ui.toggleAddedToBasketModal
  }
)
export const isKlarnaModalVisible = createSelector([selectBasketState], ui => {
  return ui.toggleKlarnaModal
})
export const isBasketError = createSelector([selectBasketState], ui => {
  return ui.setBasketError
})
