import axios from 'axios';

const BASE_URL = process.env.WORDPRESS_URL;
//const BASE_URL = "https://d3cf-31-61-229-210.ngrok.io";//process.env.WORDPRESS_URL;

export const fetchToken = (username, password) =>{

  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);

  const config = {
    method: 'POST',
    baseURL: BASE_URL,
    url: '/wp-json/jwt-auth/v1/token',
    data: formData,
    //data: queryString.stringify(data),
    headers: { 'content-type': 'multipart/form-data' },
  };

  return axios(config);
};

export const validateToken = (token) => {

  const config = {
    method: 'POST',
    baseURL: BASE_URL,
    url: '/wp-json/jwt-auth/v1/token/validate',
    headers: { Authorization: `Bearer ${token}` },
  };

  return axios(config);
};