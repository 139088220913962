import { slugify } from "./slugify"
import {KEEPERS, MILEAGE, SERVICE_HISTORY} from "./constants";

export const buildUsedBikeFeaturedBoxes = (attributes) => {
  let arrayOfAttributes = [SERVICE_HISTORY, MILEAGE, KEEPERS]
  let featuredBoxes = []
  if (attributes && attributes.nodes) {
    attributes.nodes.forEach(attr => {
      if (arrayOfAttributes.indexOf(attr.label.toLocaleLowerCase()) > -1) {
        let featuredBox = {}
        featuredBox.offerType = attr.label.toLocaleLowerCase()
        if (attr.terms && attr.terms.nodes[0]) {
          featuredBox.promotionDetails = attr.terms.nodes[0].name
        }
        featuredBoxes.push(featuredBox)
      }
    })
  }
  return featuredBoxes
}

export const filterRecommendProdBySKU = (allProducts, recommendedProd) => {
  if (
    recommendedProd === null ||
    recommendedProd === "" ||
    recommendedProd === undefined
  ) {
    return allProducts
  }
  let products = []

  if (recommendedProd.length > 0) {
    for (let i = 0; i < recommendedProd.length; i++) {
      products = products.concat(
        allProducts.filter(product => {
          if (products.includes(product)) {
            return false
          }
          return product.sku === recommendedProd[i]
        })
      )
    }
    return products
  } else return []
}

export const getSimpleProductColor = attributes => {
  if (!attributes) {
    return null
  }

  let colour = attributes.nodes
    .filter(attribute => attribute.label === "Colour")
    .reduce((colour, colourAttr) => {
      if (colourAttr.terms.nodes[0]) {
        return colourAttr.terms.nodes[0].name
      }
      return null
    }, null)

  return colour || null
}

export const getProductSize = attributes => {
  if (attributes) {
    let sizes = attributes.nodes
      .filter(item => item.label === "Size")
      .map(obj => obj.options[0])
    if (sizes.length) {
      return sizes[0]
    } else {
      return null
    }
  }
}

/**
 * we don't want to make it dynamic, instead keep it per attribute
 * otherwise we might end up overriding some product property we don't want to override
 * @param product
 */
export const assignRecognisedProductAttributes = product => {
  if (product) {
    const attributes = product.attributes
    if (attributes && attributes.nodes) {
      attributes.nodes.forEach(attribute => {
        if (!attribute || !attribute.attributeId || attribute.attributeId === 0 || !attribute.name) {
          console.warn("Broken attribute detected ", attribute.name, attribute.attributeId)
          return
        }
        let attrOptions = attribute.options
        if (!attrOptions.length) {
          console.warn('Unable to handle attribute' + attribute.attributeId)
          return
        }
        switch (attribute.name.toLowerCase()) {
          case "brand":
            product.brand = attribute.options.pop()
            break
          case "mileage":
            product.mileage = attribute.options.pop()
            break
          case "keepers":
            product.keepers = attribute.options.pop()
            break
          case "first registration date":
            product.firstRegistrationDate = attribute.options.pop()
            break
        }
      })
    }
  }
}


export const getProductBrand = attributes => {
  if (attributes) {
    const brandAttribute = attributes.nodes.filter(
      item => item.label === "Brand"
    )

    if (!brandAttribute.length) {
      return
    }

    const { options, terms } = brandAttribute[0]

    let brand = options.length && options[0]
    if (!brand && terms && terms.nodes && terms.nodes.length) {
      brand = terms.nodes[0].name
    }

    return brand
  }
}

export const decodeHtml = html => {
  if (typeof window !== "undefined") {
    const txt = document.createElement("textarea")
    txt.innerHTML = html.replaceAll('"','&quot;')
    return txt.value.replace(/(<([^>]+)>|\n)/gi, "")
  }
}

export const setInstallments = (topLevelProductCategory, price) => {
  if (Number(price) >= process.env.KLARNA_MIN_SPEND) {
    if (topLevelProductCategory === "Electric Bikes") {
      return process.env.KLARNA_BIKE_INSTALLMENTS
    } else {
      return process.env.KLARNA_NON_BIKE_INSTALLMENTS
    }
  } else {
    return null
  }
}

export const getSliderImages = (featuredImage, galleryImages) => {
  let sliderImages

  if (
    featuredImage &&
    featuredImage.node &&
    galleryImages &&
    galleryImages.nodes
  ) {
    sliderImages = [featuredImage.node, ...galleryImages.nodes]
  } else if (featuredImage && featuredImage.node) {
    sliderImages = [featuredImage.node]
  } else if (galleryImages && galleryImages.nodes) {
    sliderImages = [...galleryImages.nodes]
  }

  return sliderImages
}
export const getDescriptionTrucation = description => {
  let holderStr
  let descriptionLess
  let descriptionMore
  let trucatedDscriptionParts

  if (description) {
    if (description.length > 200) {
      holderStr = description.substring(200)
      descriptionLess = description.substring(0, 201 + holderStr.indexOf("."))
      descriptionMore = description.substring(201 + holderStr.indexOf("."))
      if (descriptionMore.length < 40) {
        trucatedDscriptionParts = [description]
      } else {
        trucatedDscriptionParts = [descriptionLess, descriptionMore]
      }
    } else {
      trucatedDscriptionParts = [description]
    }
  }
  return trucatedDscriptionParts
}

export const isProductAnElectricBicycle = (product) => {
  let bikePresent = false
  if (product && product.nodeType === "SimpleProduct" && product.productCategories && product.productCategories.nodes) {
    product.productCategories.nodes.forEach(productCategory => {
      if (productCategory.name === 'Electric Bikes') {
        bikePresent = true
      }
    })
  } else if (product && product.nodeType === "VariableProduct" && product.genericProductData && product.genericProductData.productCategories && product.genericProductData.productCategories.nodes) {
    product.genericProductData.productCategories.nodes.forEach(productCategory => {
      if (productCategory.name === 'Electric Bikes') {
        bikePresent = true
      }
    })
  }
  return bikePresent
}

export const isProductAUsedBike = (product) => {
  let usedBikeCategoryPresent = false
  if (product && product.nodeType === "SimpleProduct" && product.productCategories && product.productCategories.nodes) {
    product.productCategories.nodes.forEach(productCategory => {
      if (productCategory.name === 'Used') {
        usedBikeCategoryPresent = true
      }
    })
  } else if (product && product.nodeType === "VariableProduct" && product.genericProductData && product.genericProductData.productCategories && product.genericProductData.productCategories.nodes) {
    product.genericProductData.productCategories.nodes.forEach(productCategory => {
      if (productCategory.name === 'Used') {
        usedBikeCategoryPresent = true
      }
    })
  }
  return usedBikeCategoryPresent
}

export const SPECIFICATION_PROPERTIES = [
  { label: "Frame", value: "" },
  { label: "Rear shock", value: "" },
  { label: "Fork", value: "" },
  { label: "Seat binder", value: "" },
  { label: "Headset", value: "" },
  { label: "Stem", value: "" },
  { label: "Handlebars", value: "" },
  { label: "Grips", value: "" },
  { label: "Saddle", value: "" },
  { label: "Seatpost", value: "" },
  { label: "Rear derailleur", value: "" },
  { label: "Front derailleur", value: "" },
  { label: "Shift levers", value: "" },
  { label: "Chain", value: "" },
  { label: "Bottom bracket", value: "" },
  { label: "Crankset", value: "" },
  { label: "Chainrings", value: "" },
  { label: "Rims", value: "" },
  { label: "Front hub", value: "" },
  { label: "Rear hub", value: "" },
  { label: "Spokes", value: "" },
  { label: "Front tyre", value: "" },
  { label: "Rear tyre", value: "" },
  { label: "Inner tubes", value: "" },
  { label: "Cassette / freewheel", value: "" },
  { label: "Pedals", value: "" },
  { label: "Headlight", value: "" },
  { label: "Taillight", value: "" },
  { label: "Kickstand", value: "" },
  { label: "Rack", value: "" },
  { label: "Fenders", value: "" },
  { label: "Bell", value: "" },
  { label: "Front brake", value: "" },
  { label: "Rear brake", value: "" },
  { label: "Brake levers", value: "" },
  { label: "UI / remote", value: "" },
  { label: "Battery", value: "" },
  { label: "Charger", value: "" },
  { label: "Motor", value: "" },
  { label: "Wiring harness", value: "" },
]

export const SORT_BY_ITEMS = [
  {
    title: "Most relevant",
    sortBy: null,
    sortDirection: null,
  },
  {
    title: "Price increasing",
    sortBy: "price",
    sortDirection: "ASC",
  },
  {
    title: "Price decreasing",
    sortBy: "price",
    sortDirection: "DESC",
  },
]

export const getSpecs = attributes => {
  let specification = [...SPECIFICATION_PROPERTIES]
  let specificationDivision
  let specificationLeft
  let specificationRight

  specification.forEach(spec => {
    attributes.nodes.forEach(attr => {
      if (spec.label.toLocaleLowerCase() === attr.label.toLocaleLowerCase()) {
        // TODO: enable term name value
        // spec.value = attr.terms.nodes[0].name
        spec.value = attr.options[0]
      }
    })
  })

  specification = specification.filter(attr => attr.value)

  specificationDivision = specification.length / 2
  specificationLeft = specification.slice(0, Math.ceil(specificationDivision))
  specificationRight = specification.slice(Math.ceil(specificationDivision))
  return { specification, specificationLeft, specificationRight }
}

export const BIKE_SIZE_GUIDE_HEADINGS = [
  { heading: "Height (cm)" },
  { heading: "Inseam (cm)" },
  { heading: "Frame size (cm)" },
  { heading: "Bike size" },
]
export const BIKE_SIZE_GUIDE_DETAILS = [
  { height: "147 - 155", inseam: "61 - 79", frame: "47 - 48", bike: "XS" },
  { height: "152 - 160", inseam: "66 - 79", frame: "49 - 50", bike: "S" },
  { height: "157 - 170", inseam: "69 - 79", frame: "51 - 53", bike: "S/M" },
  { height: "168 - 178", inseam: "74 - 79", frame: "54 - 55", bike: "M" },
  { height: "175 - 183", inseam: "76 - 84", frame: "56 - 58", bike: "M/L" },
  { height: "180 - 188", inseam: "81 - 89", frame: "59 - 60", bike: "L" },
  { height: "185 - 196", inseam: "84 - 91", frame: "51 - 63", bike: "XL" },
]

//TODO remove hard coded categories to make the code more client agnostic
export const TOP_LEVEL_PRODUCT_CATEGORIES = [
  "Electric Bikes",
  "Riderwear",
  "Bike Accessories",
]

export const getTopLevelProductCategory = productCategories => {
  return productCategories.nodes
    .filter(item => TOP_LEVEL_PRODUCT_CATEGORIES.indexOf(item.name) !== -1)
    .reduce((acc, value) => acc + value.name, "")
}

export const getProductBasketProps = product => {
  let { id, stockQuantity, price } = product

  if (product.variantData) {
    ;({ id, stockQuantity, price } = product.variantData)
  }
  return { id, stockQuantity, price }
}

export const getBreadcrumbDataFromCategoryObj = categoryTree => {
  let breadcrumbData = []

  const getPath = url => url.replace('/product-category',"")
  const getNestedBreadcrumbs = obj => {
    if (obj[0] !== undefined) {
      breadcrumbData.push({name: obj[0].name, path: getPath(obj[0].link)})
      obj = obj[0].children
      if (obj.length) {
        getNestedBreadcrumbs(obj)
      }
    } else {
      console.error('FIXME breadcrumbs object was undefined', obj)
    }
  }

  getNestedBreadcrumbs(categoryTree)
  return breadcrumbData
}

export const getRequiredProductDataForGA = productData => {
  if (productData.nodeType === "SimpleProduct") {
    return getSimpleProductData(productData)
  } else if (productData.nodeType === "VariableProduct") {
    return getVariableProductData(productData)
  }
}

const getSimpleProductData = productData => {
  const {
    id,
    name,
    productCategories,
    price,
    stockQuantity,
    sku,
    attributes,
    brand,
  } = productData

  const colour = getSimpleProductColor(attributes)
  const size = getProductSize(attributes)
  let topLevelProductCategory = getTopLevelProductCategory(productCategories)

  return {
    id,
    name,
    topLevelProductCategory,
    sku,
    colour,
    size,
    price,
    stockQuantity,
    brand,
  }
}

const getVariableProductData = variantData => {
  let { name, productCategories, attributes, brand } = variantData.genericProductData

  const {
    id,
    stockQuantity,
    price,
    sku,
    size,
    colour,
  } = variantData.variantData

  if (!brand) {
    brand = getProductBrand(attributes) //TODO cleanup, brand has already been determined at createProducts, we don't need to do it again here nor anywhere else I think
  }
  let topLevelProductCategory = getTopLevelProductCategory(productCategories)

  return {
    id,
    name,
    topLevelProductCategory,
    sku,
    attributes,
    price,
    brand,
    size,
    colour,
    stockQuantity,
  }
}

export const getProductAttributeValue = (product, name) => {
  const attribute = product.attributes.nodes.find(item => item.name === name)
  if (!attribute || !attribute.options || !attribute.options.length) {
    return ""
  }

  return attribute.options.join(", ")
}

export const getProductCategories = product => {
  const categories = product.productCategories.nodes
  if (!categories || !categories.length) {
    return ""
  }

  return categories.map(item => item.name).join(", ")
}

/**
 * There is not any useful parameter returned by graphql or rest api to check
 * which category is the primary one. This method return the last category
 * on the list as a workaround solution
 * @param product
 */
export const getProductPrimaryCategory = product => {
  const categories = product.productCategories.nodes
  if (!categories || !categories.length) {
    return null
  } else {
    return categories[categories.length - 1].name
  }
}

export const getNumericPrice = price => {
  if (!price) {
    console.warn("Missing price getNumericPrice")
    return null
  }

  return Number(price.substring(1).replace(",", ""))
}

export const getDynamicProductLink = (slugs, product) => {
  if (product.productId) {
    return slugs[`${product.productId}`]
  }
  return slugs[`${product.id}`]
}

export const createDisplayPrice = (price, source) => {
  if (!price) {
    console.warn("Missing price createDisplayPrice", source)
    return null
  }
  return new Intl.NumberFormat(
    process.env.LOCAL,
    {
        style: 'currency',
        currency: process.env.CURRENCY,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }
  ).format(price);
}

export const extractVariantImages = (variantData) => {
  const {
    variantData: {
      featuredImage: variantFeaturedImg,
      galleryImages: variantGallery
    } = {},
    genericProductData: {
      featuredImage: variantGenericFeaturedImg
    } = {}
   } = variantData || {};

  return {
    variantFeaturedImg,
    variantGallery,
    variantGenericFeaturedImg
  }
}
