import {createSelector} from 'reselect';

const selectOrdersState = state => state.orders;

export const isFetchingOrders = createSelector(
  [selectOrdersState],
  (orders) => {
    return orders.isFetchingOrders;
  }
);

export const getOrders = createSelector(
  [selectOrdersState],
  (orders) => {
    return orders.orders;
  }
);

export const isError = createSelector(
  [selectOrdersState],
  (orders) => {
    return orders.isError;
  }
)